<template>
    <div>
        <div class="collab-doc">
            <div class="small-text">{{docVersions.length>1?'PREVIOUS VERSION':'CURRENT VERSION'}}</div>
            <version-card type="previous" class="mb-1" :version-title="docVersions[0].versionTitle" :version-id="docVersions[0]._id" :changes="docVersions[0].changesDescription?docVersions[0].changesDescription:''" :modifier="docVersions[0].modifier.first_name" :updatedAt="docVersions[0].updatedAt"/>
            <div v-if="docVersions.length>1">
                <div class="small-text">CURRENT VERSION</div>
                <version-card type="current" class="mb-1" :version-title="docVersions[1].versionTitle" :version-id="docVersions[1]._id" :changes="docVersions[1].changesDescription?docVersions[1].changesDescription:''" :modifier="docVersions[1].modifier.first_name" :updatedAt="docVersions[1].updatedAt"/>
            </div>
            <div class="version-btn " >
                <app-simple-button  variant="primary" @click="()=>$refs.updateVersion.showModal()">Update New Version</app-simple-button>
            </div>
        </div>
        <update-version ref="updateVersion" @next="$emit('onNext')"></update-version>
    </div>
</template>
<script>
import AppSimpleButton from "@/components/app-components/AppSimpleButton.vue"
import VersionCard from './VersionCard'
import UpdateVersion from './UpdateVersion.vue'
export default{
    props:{
        docVersions:[Array]
    },
    components:{
        VersionCard,
        AppSimpleButton,
        UpdateVersion
    },
    data(){
        return{
            //
        }
    }
}
</script>
<style scoped>
.small-text{
    font-size: 13px;
    line-height: 16px;
    font-weight:500;
    color: #4B465C;
}
.version-btn{
    position: absolute;
    bottom:20px ;
}
.collab-doc{
    height: 430px;
    overflow-y: auto;
    overflow-x: hidden;
}
.collab-doc::-webkit-scrollbar {
    width: 8px;
    position: absolute;
  }
  
  /* Track */
  .collab-doc::-webkit-scrollbar-track {
    box-shadow: inset 0 0 0px grey; 
    border-radius: 10px;
  }
   
  /* Handle */
  .collab-doc::-webkit-scrollbar-thumb {
    background: lightgray; 
    border-radius: 10px;
  }
  
  /* Handle on hover */
  .collab-doc::-webkit-scrollbar-thumb:hover {
    background: #004bff; 
  }
  @media(max-width:425px){
    .collab-doc{
        height: 300px;
        overflow-y: auto;
        overflow-x: hidden;
    }
    .version-btn{
        position: absolute;
        bottom: 160px;
      }
  }

</style>