<template>
    <div class="w-100">
        <div class="d-flex align-items-center">
            <div>
                <b-avatar
                    variant="light-primary"
                    :text="user.first_name.charAt(0).toUpperCase()"
                    />
                
            </div>
                <div class="input-bg d-flex jutify-content-between align-items-center" >
                    <input type="text" class="w-100" v-model="text" placeholder="Write comment">
                    <img :src="require('@/assets/images/app-icons/send_icon.png')" alt="" class="cursor-pointer " @click="sendMessage">
                </div>
        </div>
    </div>

</template>
<script>
import {BAvatar,BInputGroup, BFormInput,BInputGroupAppend} from 'bootstrap-vue'
export default{
    components:{
        BAvatar,
        BInputGroup, 
        BFormInput,
        BInputGroupAppend
    },
    data(){
        return{
            text:'',
            user: JSON.parse(localStorage.getItem('user'))
        }
    },
    methods:{
        sendMessage(){
            console.log('send message:',this.text)
            this.$emit('sendMessage',this.text)
            this.text = ''
        }
    }
}
</script>
<style scoped>
.input-bg{
    background-color: #f2f5fa !important;
    padding: 5px 5px 5px 10px;
    margin-left: 8px;
    width: 100%;
    border-radius: 30px;
}
input[type='text']{
    border: none ;
    background-color: #f2f5fa !important;
    outline: none;
}
</style>