import http from "@/utils/http";
export async function create_signature(data){
    const req = (await http.post(`/user/signature`,data));
    return req.data; 
}
export async function get_signature(id){
    const req = (await http.get(`/user/signature`));
    return req.data; 
}

export async function create_typed_signature(data){
    const req = (await http.post(`/user/signature-object`,data));
    return req.data; 
}

export async function get_typed_signature(){
    const req = (await http.get(`/user/signature-object`));
    return req.data; 
}

export async function submit_query(data) {
    const req = (await http.post('/support-queries',data));
    return req.data;   
}

export async function updrade_to_service_provider(){
    const req = (await http.put(`/user/upgrade-to-service-provider`));
    return req.data; 
}