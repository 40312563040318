<template>
    <div class="d-flex w-100">
        <div class="m-8 ml-0 my-0">
            <b-avatar
                    variant="light-primary"
                    :text="sentBy.charAt(0).toUpperCase()"
                />
                <!-- <div v-if="hasReplies" class="vertical-stock mx-auto"></div> -->
        </div>

        <div class="w-100">
            <div class="d-flex">
                <div class="name-heading mr-auto">{{sentBy.charAt(0).toUpperCase()+sentBy.slice(1)}} <span style="opacity: .7;">Commented</span></div>
                <div class="time-heading">{{getDiffernce(updatedAt)}}</div>
            </div>
            <div class="comment-card">
                <p class="small-heading mb-0">{{comment}}</p>
            </div>
            <slot name="reply"></slot>
            <div class="small-heading text-primary m-8 mx-0 cursor-pointer" v-if="!showReply" @click="()=>showReply=true">Reply</div>
            <div class="input-bg m-8 mx-0 " v-if="showReply">
                <input type="text"  class="w-100" v-model="text" placeholder="Write comment">
                <img :src="require('@/assets/images/app-icons/send_icon.png')" alt="" class="cursor-pointer ml-auto" @click="sendReply">
            </div>
        </div>
    </div>
</template>
<script>
import {BAvatar} from 'bootstrap-vue'
import moment from 'moment';
export default{
    props:{
        comment:[String],
        sentBy:[String],
        updatedAt:[String],
        hasReplies:{
            type:[Boolean],
            default:false
        }
    },
    components:{
        BAvatar
    },
    data(){
        return{
            text:'',
            showReply:false,
            //
        }
    },
    methods:{
        getDiffernce(startTime){
            let endTime = moment(new Date)
            var duration = moment.duration(endTime.diff(moment(startTime)));
            if(duration.asYears().toFixed(0) >0){
                return duration.asYears().toFixed(0)+' year ago'
            }
            else if(duration.asMonths().toFixed(0) >0){
                return duration.asMonths().toFixed(0)+' month ago'
            }
            else if(duration.asDays().toFixed(0) >0){
                return duration.asDays().toFixed(0)+'d ago'
            }
            else if(duration.asHours().toFixed(0) >0){
                return duration.asHours().toFixed(0)+'h ago'
            }
            else if(duration.asMinutes().toFixed(0)>0){
                return duration.asMinutes().toFixed(0)+'m ago'
            }
            else if(duration.asSeconds().toFixed(0)>0){
                return duration.asSeconds().toFixed(0)+'s ago'
            }
        },
        sendReply(){
            this.$emit('reply',this.text)
            this.text = ''
            this.showReply = false
        }
    }
}
</script>
<style scoped>
.comment-card{
    border-radius: 6px;
    background-color: #f8fafc;
    padding: 8px;
    margin-top: 6px;
}
.name-heading{
    line-height: 16px;
    font-weight: 500;
    size:13px;
    color: #000000;

}
.time-heading{
    line-height: 12px;
    font-weight: 500;
    size:12px;
    color: #000000;
}
.small-heading{
    line-height: 16px;
    font-weight: 400;
    size:13px;
    color: #5D596C;

}
.m-8{
    margin: 8px;
}
.vertical-stock{
    width: 1px;
    height: 100%;
    background-color: #EAECF0;
}
.input-bg{
    background-color: #f2f5fa !important;
    padding: 3px 6px;
    margin-left: 8px;
    display: flex;
    align-items: center;
    border-radius: 30px;
}
input[type='text']{
    border: none ;
    background-color: #f2f5fa !important;
    outline: none;
}
</style>