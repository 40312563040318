<template>
    <document-viewer-2 ref="docViewer" @currentPage="(p)=>$emit('currentPage',p)" :default-signature-boxes="defaultSignatureBoxes" :initialDoc="pdfDocument"/>
</template>
<script>

import DocumentViewer2 from "./DocumentViewer2.vue"
import { PDFDocument} from 'pdf-lib';
import {sign_document} from '@/apis/others/documents'
export default {
  props: { 
    initialDoc: { type: String },
    defaultSignatureBoxes:[Array],
   },
  components: {
    DocumentViewer2
  },
  data() {
    return {
     show:false,
     pdfDocument:'',
     pdfBytes:'',
     signaturePlacement:[],
     width:0,
     height:0,
     containerDomrect:null
    }
  },
  mounted() {
    this.modifyPdf()
    setTimeout(()=>{
      const container = document.querySelector('.pdf-div');
      this.containerDomrect = container.getBoundingClientRect();
    },1000)
    // console.log('vue',this.initialDoc, pdf)
  },
  methods: {
    async modifyPdf() {
      const url = this.initialDoc
      const existingPdfBytes = await fetch(url).then(res => res.arrayBuffer())
      const pdfDoc = await PDFDocument.load(existingPdfBytes)
      const pdfBytes= await pdfDoc.save()
      let base64String = btoa(
          new Uint8Array(pdfBytes).reduce(
            (data, byte) => data + String.fromCharCode(byte),
            '',
          ),
      );
      this.pdfDocument='data:application/pdf;base64,' + base64String
    },
    addSignatureArea(details){

      this.$refs.docViewer.addSignatureBox(details)
    },
    setReceiverSign(data){
      this.signaturePlacement=[data];
      this.$refs.docViewer.currentPage=data.pageNo;
      this.$refs.docViewer.receiverData=data;
    },
    async signDoc(signature){
      const url = this.initialDoc
      const existingPdfBytes = await fetch(url, {cache: "no-cache"}).then(res => res.arrayBuffer())
      const pdfDoc = await PDFDocument.load(existingPdfBytes)
      const pages = pdfDoc.getPages()
      const currentPage = pages[this.signaturePlacement[0].pageNo-1]
      const pageSize= currentPage.getSize()
      const imageUrl = signature;
      const image = await pdfDoc.embedPng(imageUrl);
      const xPosition = (this.signaturePlacement[0].x/ 100) * this.signaturePlacement[0].canvasWidth;
      const yPosition = (this.signaturePlacement[0].y/100) *  this.signaturePlacement[0].canvasHeight;

        // const xPosition = (this.signaturePlacement[0].x/ 100) * this.containerDomrect.width;
        // const yPosition = (this.signaturePlacement[0].y/100) *  this.containerDomrect.height;
        const signatureXDiff=this.signaturePlacement[0].canvasWidth/xPosition
        const signatureYDiff=this.signaturePlacement[0].canvasHeight/yPosition
        console.log('signature X', pageSize.width,pageSize.width/signatureXDiff, this.signaturePlacement[0].canvasWidth*.30)
        currentPage.drawImage(image, {
            // x: pageSize.width/signatureXDiff,
            x:pageSize.width/signatureXDiff+this.signaturePlacement[0].canvasWidth*.30>pageSize.width?pageSize.width-this.signaturePlacement[0].canvasWidth*.30:pageSize.width/signatureXDiff>0?pageSize.width/signatureXDiff-50:pageSize.width/signatureXDiff,
            y:pageSize.height-pageSize.height/signatureYDiff-140,
            width:this.signaturePlacement[0].canvasWidth*.30,
            height:100
          })
      const pdfBytes= await pdfDoc.save()
      this.pdfBytes=pdfBytes;
      let base64String = btoa(
          new Uint8Array(pdfBytes).reduce(
            (data, byte) => data + String.fromCharCode(byte),
            '',
          ),
      );
      this.pdfDocument='data:application/pdf;base64,' + base64String
      if(base64String){
        this.$emit('pdfSigned')
      }
      
    },
    submitSignature(){
      const blob = new Blob([this.pdfBytes], { type: 'application/pdf' });
      let data= new FormData();
      data.append('file',blob);
      sign_document(this.$route.query.id, data).then((resp)=>{
        this.$bvToast.toast(resp.message, {
              title: 'Signed successfully',
              variant: 'success',
              solid: true,
            })
        setTimeout(()=>this.$router.push('/documents'),[1000])
      })
    },
    getAxis(c,v,w){
      if(c<v){
        return c
      }else{
        return c-v;
      }
    },
    getYAxis(h,c,v){
      if(c<v){
        return h-c-v;
      }else if(c<h/2){
        return h-c-v/2;
      }else{
        return h-c;
      }
    },
  }
}
</script>