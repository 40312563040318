<template>
    <div>
        <div class="d-flex my-1">
            <!-- <div class="reply-margin">

            </div> -->
            <div class="m-8 ml-0 my-0">

                <b-avatar
                    variant="light-info"
                    :text="sentBy.charAt(0).toUpperCase()"
                    />
            </div>
            <div class="w-100">
                <div class="d-flex justify-content-between">
                    <div class="name-heading">{{sentBy.charAt(0).toUpperCase()+sentBy.slice(1)}} <span style="opacity: .7;">Replied </span> </div>
                    <div class="time-heading">{{getDiffernce(updatedAt)}}</div>
                </div>
                <div class="comment-card">
                    <p class="small-heading mb-0">{{ comment }}</p>
                </div>
            </div>
        </div>
    </div>
    
</template>
<script>
import {BAvatar} from 'bootstrap-vue'
import moment from 'moment';
export default{
    props:{
        comment:[String],
        sentBy:[String],
        updatedAt:[String],
    },
    components:{
        BAvatar
    },
    data(){
        return{
            //
        }
    },
    methods: {
        getDiffernce(startTime){
            let endTime = moment(new Date)
            var duration = moment.duration(endTime.diff(moment(startTime)));
            if(duration.asYears().toFixed(0) >0){
                return duration.asYears().toFixed(0)+' year ago'
            }
            else if(duration.asMonths().toFixed(0) >0){
                return duration.asMonths().toFixed(0)+' month ago'
            }
            else if(duration.asDays().toFixed(0) >0){
                return duration.asDays().toFixed(0)+'d ago'
            }
            else if(duration.asHours().toFixed(0) >0){
                return duration.asHours().toFixed(0)+'h ago'
            }
            else if(duration.asMinutes().toFixed(0)>0){
                return duration.asMinutes().toFixed(0)+'m ago'
            }
            else if(duration.asSeconds().toFixed(0)>0){
                return duration.asSeconds().toFixed(0)+'s ago'
            }
        },
    },
}
</script>
<style scoped>
.comment-card{
    border-radius: 6px;
    background-color: #f8fafc;
    padding: 8px;
    margin-top: 6px;
}
.name-heading{
    line-height: 16px;
    font-weight: 500;
    size:13px;
    color: #000000;

}
.time-heading{
    line-height: 12px;
    font-weight: 500;
    size:12px;
    color: #000000;
}
.small-heading{
    line-height: 16px;
    font-weight: 400;
    size:13px;
    color: #5D596C;

}
.m-8{
    margin: 8px;
}
.curved-stock{
    width: 30px;
    height: 20px;
    border-bottom: 2px solid #EAECF0;
    border-radius: 50%;
    transform: rotate(45deg);
}
.stock{
    height: 100%;
    border-left: 2px solid #EAECF0;
}
.reply-margin{
    margin-left: 17px;
    margin-right: 8px;
}
</style>