<template>
    <p class="normal-text"><slot></slot></p>
</template>
<script>
    export default {
        
    }
</script>
<style>
.normal-text{
    color:#4B465C;
    font-size: 15px;
    font-weight: 400;
    line-height: 22px;
    margin: 0px;; 
}
</style>