<template>
    <div  class="position-relative h-100">
        <b-overlay
            :show="loading"
            rounded
            opacity="0.6"
            spinner-variant="primary"
            class="collab-doc "
        >
            <div v-for="(comment,index) in comments " :key="index"  >
                <comment :comment="comment.text" :sent-by="comment.sentBy.first_name" :updated-at="comment.updatedAt" class="w-100" :hasReplies="comment.replies.length?true:false" @reply="(c)=>addComment(c,comment._id)" >
                    <template #reply>
                        <reply v-for="(reply,index) in comment.replies" :key="index" :comment="reply.text" :sent-by="reply.sentBy.first_name" :updated-at="reply.updatedAt" class="w-100" />
                    </template> 
                </comment>
            </div>
            <div v-if="!comments.length">No comment available</div>
        </b-overlay>
        <div class="comment-input-position w-100">
            <comment-input  @sendMessage="addComment" />
        </div>
    </div>
</template>
<script>
import Comment from "./Comment.vue"
import Reply from "./Reply.vue"
import CommentInput from "./CommentInput.vue"
import {add_comment,get_comments} from "@/apis/others/documents"
import { BOverlay } from "bootstrap-vue"
import moment from "moment"
export default{
    components:{
        Comment,
        Reply,
        CommentInput,
        BOverlay
    },
    data(){
        return{
            comments:[],
            loading:false
        }
    },
    mounted(){
        this.getComments()
    },
    methods:{
        addComment(comment,pcomment=null){
            let data={
                text:comment,
                document:this.$route.query.id,
                parentCommentId:pcomment
            }
            add_comment(data).then(resp=>{
                this.getComments()
            })
        },
        getComments(){
            this.loading=true
            get_comments(this.$route.query.id).then(resp=>{
                this.loading=false
                this.comments = resp.data
            })
        },
        getTimeDifference(time){
            var duration = moment.duration(end.diff(startTime));
            var hours = duration.asHours();
            return hours
        }
    }
}
</script>
<style scoped>

    .collab-doc{
        height: 430px;
        overflow-y: auto;
        overflow-x: hidden;
    }
    .collab-doc::-webkit-scrollbar {
        width: 8px;
        position: absolute;
      }
      
      /* Track */
      .collab-doc::-webkit-scrollbar-track {
        box-shadow: inset 0 0 0px grey; 
        border-radius: 10px;
      }
       
      /* Handle */
      .collab-doc::-webkit-scrollbar-thumb {
        background: lightgray; 
        border-radius: 10px;
      }
      
      /* Handle on hover */
      .collab-doc::-webkit-scrollbar-thumb:hover {
        background: #004bff; 
      }
      .comment-input-position{
        position: absolute;
        bottom: 50px;
      }
      @media(max-width:425px){
        .collab-doc{
            height: 300px;
            overflow-y: auto;
            overflow-x: hidden;
        }
        .comment-input-position{
            position: absolute;
            bottom: 180px;
          }
      }
</style>