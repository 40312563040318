<template>
    <div class="card card-width">
        <b-alert :show="showError" variant="danger" class="p-1 w-100">
            <b>Error: </b> Render failed because current file is corrupted. Please upload a new file and try again!
           </b-alert>
        <div class="row p-2 justify-content-between align-items-center">
            <div class="col-8">
                <app-heading-2>{{documentTitle}}</app-heading-2>
            </div>
            <b-dropdown
            class="d-block d-lg-none"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            text="Block Level Dropdown Menu"
            right
            variant="none"
            size="sm"
            boundary="viewport"
            no-caret
            >
            <template #button-content>
                <feather-icon
                    icon="MoreVerticalIcon"
                    size="18"
                />
            </template>
            <b-dropdown-item @click="()=>showComments=true">Comments</b-dropdown-item>
            <b-dropdown-item @click="()=>showVersionHistory=true">Version History</b-dropdown-item>
        </b-dropdown>
        </div>
        <b-sidebar class="d-lg-none "  v-model="showComments" backdrop backdrop-variant="light" width="531px" bg-variant="white" right no-header-close>
            <div class="p-2 d-flex align-items-center border-bottom mt-5">
                <app-heading-2 class="mt-1"><feather-icon icon="ChevronLeftIcon" size="22" @click="()=>showComments=false" ></feather-icon>Comments</app-heading-2>
                <feather-icon class="pull-up ml-auto mr-2 text-primary cursor-pointer" size="20" icon="RefreshCwIcon" @click="$refs.comments1.getComments()" />
            </div>
            <div class="p-2 h-100">
                <comments-view ref="comments1" class="w-100" /> 
            </div>
        </b-sidebar>
        <b-sidebar class="d-lg-none "  v-model="showVersionHistory" backdrop backdrop-variant="light" width="531px" bg-variant="white" right no-header-close>
            <div class="p-2 d-flex align-items-center border-bottom mt-5">
                <app-heading-2 class="mt-1"><feather-icon icon="ChevronLeftIcon" size="22" @click="()=>showVersionHistory=false" ></feather-icon>Version History</app-heading-2>
            </div>
            <div class="p-2 h-100">
                <versions :doc-versions="documentVersions" @onNext="getDocument($route.query.id)"/>
            </div>
        </b-sidebar>
        <div class="px-2 pb-2">
            <div class="main-card row">
                <div class="col-lg-9 col-12">
                    <document-viewer-2 ref="docViewer" :default-signature-boxes="signaturePlacements" @currentPage="(p)=>currentPage=p" @renderFailed="()=>showError=true"  v-if="document" :initialDoc="document" :moveSign="false"/>
                </div>
                <div class="col-3 side-card d-lg-block d-none position-relative">
                    <div class="p-2 h-100">
                        <div class="d-flex">
                            <div class="mr-1">
                                <div class="cursor-pointer tab-heading" :class="[active==0?'text-primary':'']" @click="changeTab(0)">Comments</div>
                            </div>
                            <div>
                                <div class="cursor-pointer tab-heading" :class="[active==1?'text-primary':'']" @click="changeTab(1)">Version History</div>
                                
                            </div>
                            <feather-icon class="pull-up ml-auto text-primary cursor-pointer" icon="RefreshCwIcon" @click="$refs.comments.getComments()" />
                        </div>

                        <comments-view ref="comments" v-if="active==0"/>
                        <versions v-if="active==1" :doc-versions="documentVersions" @onNext="getDocument($route.query.id)"/>
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>
<script>
import AppHeading2 from '@/components/app-components/AppHeading2.vue'
import AppSimpleButton from '@/components/app-components/AppSimpleButton.vue';
import SignDocument from '@/components/functional-components/other/documents/SignDocument.vue';
import CommentsView from '@/components/functional-components/other/documents/comments/CommentsView.vue';
import DocumentViewer2 from "@/components/functional-components/other/documents/DocumentViewer2.vue"
import Versions from '@/components/functional-components/other/documents/version-history/Versions.vue'
import DocViewer from "@/components/functional-components/other/documents/DocViewer.vue"
import {BAlert, BSidebar, BDropdown, BDropdownItem} from 'bootstrap-vue'
import {get_document} from '@/apis/others/documents'
export default{
    components:{
        AppHeading2,
        AppSimpleButton,
        SignDocument,
        CommentsView,
        Versions,
        DocViewer,
        BAlert,
        DocumentViewer2,
        BSidebar,
        BDropdown,
        BDropdownItem
    },
    data(){
        return{
            sign:false,
            active:0,
            document:'',
            documentTitle:'',
            signaturePlacements:[],
            documentVersions:[],
            showError:false,
            showComments:false,
            showVersionHistory:false,
            requireSignature:false
        }
    },
    mounted(){
        this.getDocument(this.$route.query.id)
    },
    methods:{
        getDocument(id){
            get_document(id).then(resp=>{
                this.document = resp.data.fileCdnLink;
                this.documentTitle = resp.data.document.documentName
                this.documentVersions=resp.data.document.versions
                if(resp.data.document.signaturesRequired){
                    this.signaturePlacements = resp.data.document.signaturePlacements
                }
            })
        },
        changeTab(tab){
            this.active=tab
        },
        setCurrentPage(p){
            this.loaded=true;
            this.currentPage=p
        }
    }
}
</script>
<style scoped> 
.main-card{
    border-radius: 8px;
    height: 100%;
    border: 1px solid #EAECF0;
    background-color: #f2f5fa;
}
.side-card{
    background-color: #ffffff;
    border-radius: 0px 8px 8px 0px;
}
.tab-heading{
    font-size: 16px;
    font-weight: 500;
    color: #4B465C;
    margin-bottom: 22px;
}
.tab-heading:hover{
    color: #004bff;
}
@media(max-width:768px){
    .b-sidebar-outer{
        z-index: 10 !important;
    }
}
</style>