<template>
    <div class="heading-1">
        <slot></slot>
    </div>
</template>
<script>
export default{
    data(){
        return{
            //
        }
    }
}
</script>
<style scoped>
 .heading-1{
        color: #4B465C;
        font-feature-settings: 'clig' off, 'liga' off;
        font-size: 2rem;
        font-weight: 500;
        line-height: 36px;
    }
</style>