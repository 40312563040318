<template>
    <div class="version-card">
        <div class="small-heading">Version title</div>
        <div class="title border-bottom">{{versionTitle}}</div>
        <div v-if="changes.length">
            <div class="small-heading mt-1">Changes description</div>
            <div class="text-small border-bottom">
                <p class="mb-1">{{changes}}</p>
            </div>
        </div>
        
        <div class="d-flex justify-content-between align-items-center mt-1">
            <div>
                <b-avatar
                    class="pull-up"
                    :src="require('@/assets/images/portrait/small/avatar-s-5.jpg')"
                />
                <span class="name-heading">{{modifier}}</span>
            </div>
            <div class="date-text">{{getFormatedDate(updatedAt,"DD MMM YYYY")}}</div>
        </div>
        <div class="d-flex mt-1">
            <b-overlay
                :show="downloadLoading"
                rounded
                opacity="0.6"
                spinner-small
                spinner-variant="primary"
                class="d-inline-block "
            >
                <b-button variant="primary" class="mr-1" @click="getDocumnet()" >Download</b-button>
            </b-overlay>
            <!-- <b-overlay
                :show="backupLoading"
                rounded
                opacity="0.6"
                spinner-small
                spinner-variant="primary"
                class="d-inline-block "
            >
                <b-button v-if="type=='current'" variant="outline-primary" @click="saveToBackup()">Save in Backup</b-button>
            </b-overlay> -->
        </div>
    </div>
</template>
<script>
import { BAvatar,BButton } from 'bootstrap-vue';
import {document_version,save_to_backup} from '@/apis/others/documents'
import moment from 'moment'
export default{
    components:{
        BAvatar,
        BButton
    },
    props:{
        type:[String],
        versionTitle:[String],
        versionId:[String],
        changes:[String],
        modifier:[String],
        updatedAt:[String],
    },
    data(){
        return{
            downloadLoading:false,
            backupLoading:false,
        }
    },
    methods:{
        getFormatedDate(date,format){
            return moment(date).format(format)
        },
        getDocumnet(){
            this.downloadLoading=true
            document_version({id:this.$route.query.id, versionId:this.versionId}).then(resp=>{
                this.downloadLoading=false
                this.download(resp.data.fileLink, resp.data.documentName)
            })
        },
        download(url,name){
            var link = document.createElement("a");
            link.setAttribute('download',name);
            link.href = url;
            link.target = '_blank';
            document.body.appendChild(link);
            link.click();
            link.remove();
        },
        saveToBackup(){
            this.backupLoading=true
            save_to_backup(this.$route.query.id).then(resp=>{
                this.backupLoading=false
                this.$bvToast.toast(resp.message, {
                        title: 'Saved to backup',
                        variant: 'success',
                        solid: true,
                    })
            }).catch(error => {
                this.backupLoading=false
                this.$bvToast.toast(error.message, {
                    title: error.error,
                    variant: 'danger',
                    solid: true,
                }) 
             })
        },
    }
}
</script>
<style scoped>
.version-card{
    border-radius: 6px;
    background-color: #f8fafc;
    padding: 8px;
    margin-top: 6px;
}
.small-heading{
    line-height: 22px;
    font-weight: 400;
    size:14px;
    color: #000000;

}
.title{
    font-weight: 500;
    line-height: 26px;
    font-size: 16px;
    color: #000000;
    padding-bottom: 4px;
}

.text-small{
    line-height: 16px;
    font-weight: 400;
    size:13px;
    color: #5D596C;
}
.name-heading{
    font-weight: 500;
    line-height: 12px;
    font-size: 10px;
    color: #000000;
    margin-left: 4px;
}
.date-text{
    font-weight: 400;
    line-height: 15px;
    font-size: 12px;
    color: #4B465C;
}
</style>